<template>
  <div class="container" v-loading="loading">

      <div class="row course-part">
        <div class="video-part-title">最新课程</div>
        <div class="card-deck" >
          <div class="col-xs-12 col-sm-6 col-md-4" v-for="item in list" :key="item.id">
            <div class="card">
              <router-link :to="{ path: item.link, query: { id: item.id } }">
                <img class="card-img-top"
                  :src="item.image"
                  :item="item.title"
                  :alt="item.title" />
              </router-link>
              <div class="card-body">
                <p class="card-text">
                  <small class="text-muted">更新时间：{{ item.update_time }}</small>
                </p>
                <h5 class="card-title">
                  <router-link :to="{ path: item.link, query: { id: item.id } }">
                    {{ item.title }}
                  </router-link>
                </h5>
                <p class="card-text">
                  <small class="text-muted">{{ item.author }} 发布</small>
                  <small class="text-muted right">浏览：{{ item.view }}</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  // name: 'Index',
  props:['user'],
  data() {
    return {
      list: null,
      loading: true,
    }
  },
  created() {
    this.getNewCourseList()
  },
  methods: {
    async getNewCourseList() {     
      const response = await axios.get('/api/web/course/newList')
      const items = response.data.data.data
      this.list = items
      // console.log(this.list)
      this.loading = false
    },
    async course(id) {
      this.$router.push({path:`/course/${id}`})
    }
  }
}
</script>
